<template>
  <div class="auth__form__content" id="auth-login">
    Your Account Has Been Activated
    <form v-on:submit="resetPassword">
      <h2 class="has-text-centered">RESET PASSWORD</h2>
      <b-field label="New Password">
        <b-input
          v-model="password"
          type="password"
          placeholder="password"
          required
        ></b-input>
      </b-field>
      <b-field class="is-flex is-justify-content-center">
        <b-button native-type="submit">Submit</b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        token: this.$route.params.token,
        userId: null,
        password: null,
      },
    }
  },

  methods: {
    checkActivateAccountToken() {
      let token = this.form.token
      this.$store
        .dispatch('auth/checkActivateAccountToken', { token })
        .then(() => {
          console.log('success')
        })
        .catch((error) => {
          this.$router.push('/auth/login')
          console.log(error)
        })
    },
    resetPassword: function (e) {
      e.preventDefault()
      let token = this.token
      let userId = this.userId
      let password = this.password
      let redirect = '/auth/login'
      this.$store
        .dispatch('auth/resetPassword', { token, userId, password, redirect })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: `Success Change Password!`,
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    checkResetPasswordToken() {
      let token = this.form.token
      this.$store
        .dispatch('auth/checkResetPasswordToken', { token })
        .then((response) => {
          this.userId = response.userId
          this.token = response.token
        })
        .catch((error) => {
          this.$router.push('/auth/login')
          console.log(error)
        })
    },
  },
  created() {
    if (this.form.token != null) {
      this.checkActivateAccountToken()
      this.checkResetPasswordToken()
    }
  },
}
</script>
